import './Login.css'
import CdcScreenSet from '../../components/CdcScreenSet'
import LoadingSpinner from '../../components/LoadingSpinner'
import Redirect from '../../components/Redirect'
import CompleteRegistrationPage from '../../views/CompleteRegistrationPage'
import { CdcProvider, useCdcContext } from '../../providers/CdcProvider'
import { useClientConfig } from '../../providers/ClientConfigProvider'
import React, { useEffect, useContext } from 'react'
import useExternalScripts from "../../hooks/useExternalScripts"
import { useConfig } from '../../providers/ConfigProvider'
import { ContentGroupContext } from '../../providers/ContentGroupProvider'

function isLocalAndSessionStorageSupported() {
    try {
        const sessionStorage = window.sessionStorage
        sessionStorage.setItem('test', 'test')
        sessionStorage.getItem('test')
        sessionStorage.removeItem('test')
        const localStorage = window.localStorage
        localStorage.setItem('test', 'test')
        localStorage.getItem('test')
        localStorage.removeItem('test')
        return true
    } catch (e) {
        return false
    }
}

function LoginPage() {
    const { isCdcSdkReady, dispatch, user, state } = useCdcContext()
    const { clientConfig } = useClientConfig()
    const { config, state: configState } = useConfig()
    useExternalScripts({
        src: config?.dataWidget?.src,
        type: 'module',
        id: 'dataWidgetScript',
        defer: true
    }, configState)

    // Part of WebSDK forgetting OIDC Context error workaround - store relevant information for restoration in localStorage
    if(isLocalAndSessionStorageSupported){
        window.localStorage.setItem('initialGigyaCache', window.sessionStorage.getItem('gigyaCache'))
        window.localStorage.setItem('lastLoginUrl', window.location?.href)
    }
    
    useEffect(() => {
        if (isCdcSdkReady === true) {
            window.gigya.accounts.addEventHandlers({
                onLogin: async function (event) {
                    dispatch({
                        type: 'userAvailable',
                        payload: event
                    })
                }
            })
        }
    }, [isCdcSdkReady, dispatch])

    const { isMappScriptReady } = useContext(ContentGroupContext)
    useEffect(() => {
        if(isMappScriptReady && state === 'done' && user){
            window.dispatchEvent(new CustomEvent('cdc-screen-loaded', {
                detail: {
                    cg6: 'login',
                    cg7: 'logged-in',
                    uid: user.uid
                }
            }))
        }
    }, [isMappScriptReady, state, user])

    return (
        <React.Fragment>
            <CdcProvider.User.Loading>
                <div className="content login loading-spinner">
                    <LoadingSpinner />
                </div>
            </CdcProvider.User.Loading>
            <CdcProvider.User.Unavailable>
                <div className="content login">
                    <CdcScreenSet 
                        screenSet={ clientConfig?.cdc?.screenSets?.registrationLogin }
                        startScreen={ clientConfig?.cdc?.screens[clientConfig?.startScreen] }
                    />
                </div>
            </CdcProvider.User.Unavailable>
            <CdcProvider.User.Available>
                <Redirect to={"/proxy?mode=afterLogin"} />
            </CdcProvider.User.Available>
            <CdcProvider.User.PendingRegistration>
                <CompleteRegistrationPage />
            </CdcProvider.User.PendingRegistration>
        </React.Fragment>
    )
}

export default LoginPage
